import React from 'react';
import './styles/EnSavoirPlus.css';
import projectImage1 from '../assets/images/project-1.jpg';
import projectImage2 from '../assets/images/project-2.jpg';

function EnSavoirPlus() {
  return (
    <div className="details-container">
      {/* Barre de séparation supplémentaire */}
      <div className="section-divider-bottom"></div>
      <header className="details-header">
        <h1>Restauration de la forêt de cocotiers</h1>
        <img src={projectImage1} alt="Forêt de cocotiers" />
      </header>

      <div className="space-between-headers"></div>

      <header className="details-header">
        <h1>Préservation de la barrières de coralliens</h1>
        <img src={projectImage2} alt="Forêt de cocotiers" />
      </header>

      {/* Barre de séparation supplémentaire */}
      <div className="section-divider-bottom"></div>

      {/* Présentation du projet */}
      <section className="details-intro">
        <h2>Pourquoi restaurer la forêt de cocotiers ?</h2>
        <p>
          La forêt de cocotiers de Raraka est un élément essentiel de l'écosystème local. Malheureusement, elle a été endommagée par des facteurs naturels et humains,
          tels que les tempêtes tropicales et les activités non durables. Notre projet vise à replanter des cocotiers, créer des barrières de protection contre l'érosion,
          et sensibiliser la population à l'importance de la conservation de cette ressource précieuse.
        </p>
      </section>

      {/* Barre de séparation supplémentaire */}
      <div className="section-divider-bottom"></div>

      {/* Objectifs du projet */}
      <section className="details-objectifs">
        <h2>Nos objectifs</h2>
        <ul>
          <li>Replanter 500 cocotiers sur les zones les plus touchées d'ici la fin de l'année.</li>
          <li>Créer des barrières de protection pour lutter contre l'érosion côtière.</li>
          <li>Sensibiliser 300 habitants de Raraka à l'importance de préserver la forêt de cocotiers.</li>
        </ul>
      </section>

      {/* Barre de séparation supplémentaire */}
      <div className="section-divider-bottom"></div>

      {/* Témoignages */}
      <section className="details-temoignages">
        <h2>Témoignages</h2>
        <blockquote>
          <p>"Voir les jeunes de notre communauté replanter des cocotiers me donne tellement d'espoir pour l'avenir de Raraka." - Tane, habitant de Raraka</p>
        </blockquote>
        <blockquote>
          <p>"Ce projet est crucial pour la survie de notre environnement et pour transmettre nos traditions aux générations futures." - Maeva, bénévole</p>
        </blockquote>
      </section>

      {/* Barre de séparation supplémentaire */}
      <div className="section-divider-bottom"></div>

      {/* Comment s'impliquer */}
      <section className="details-participation">
        <h2>Comment pouvez-vous nous aider ?</h2>
        <p>
          Vous pouvez nous aider de plusieurs manières : en rejoignant notre équipe de bénévoles sur le terrain, en faisant un don pour financer l'achat de jeunes cocotiers,
          ou en parrainant un lot d'arbres à replanter. Chaque geste compte et nous rapproche de notre objectif de restaurer la forêt de cocotiers.
        </p>
        <button className="details-button">Faire un don</button>
        <button className="details-button">Devenir bénévole</button>
      </section>
    </div>
  );
}

export default EnSavoirPlus;