import React, { useEffect, useState } from 'react';
import './styles/Coordonnees.css';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import contactImage from '../assets/images/contact.jpg';
import matthieub from '../assets/images/president.png';
import mathildeb from '../assets/images/vice-president.png';
import { addContactMessage } from '../services/firestoreService';

function Coordonnees() {
  const [contactForm, setContactForm] = useState({
    name: '',
    email: '',
    message: '',
  });
  const [formMessage, setFormMessage] = useState('');

  useEffect(() => {
    if (!L.DomUtil.get('map')) {
      const map = L.map('map').setView([-15.1167, -147.55], 13);

      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      }).addTo(map);

      L.marker([-15.1167, -147.55]).addTo(map)
        .bindPopup('Nous sommes ici à Raraka')
        .openPopup();
    }
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setContactForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormMessage('');

    if (!contactForm.email || !contactForm.message) {
      setFormMessage('Veuillez remplir tous les champs obligatoires.');
      return;
    }

    try {
      await addContactMessage(contactForm, 
        () => setFormMessage('Votre message a été envoyé avec succès.'),
        (error) => setFormMessage(`Erreur : ${error}`)
      );
      setContactForm({ name: '', email: '', message: '' }); // Réinitialise le formulaire
    } catch (error) {
      setFormMessage(`Erreur : ${error.message}`);
    }
  };

  return (
    <div className="coordonnees-container">
      <header className="coordonnees-header">
        <h1>Coordonnées</h1>
        <p>
          Découvrez l'équipe derrière Te Ora O Raraka, ainsi que notre mission pour préserver la biodiversité et soutenir le développement local durable.
        </p>
      </header>

      {/* Barre de séparation supplémentaire */}
      <div className="section-divider-bottom"></div>

      {/* Section Membres Fondateurs et Présidents */}
      <section className="membres-section">
        <h2>Membres Fondateurs et Présidents</h2>
        <div className="membres-container">
          <div className="membre">
            <div className="membre-image">
              <img src={matthieub} alt="Matthieu B - Fondateur" />
            </div>
            <div className="membre-info">
              <h3>Matthieu B - Fondateur</h3>
              <p>Étudiant en ingénierie informatique</p>
            </div>
          </div>
          <div className="membre">
            <div className="membre-image">
              <img src={mathildeb} alt="Mathilde B - Vice-présidente" />
            </div>
            <div className="membre-info">
              <h3>Mathilde B - Vice-présidente</h3>
              <p>Étudiante en école de commerce</p>
            </div>
          </div>
          <div className="membre">
            <div className="membre-image">
              <img src={mathildeb} alt="Mathilde B - Vice-présidente" />
            </div>
            <div className="membre-info">
              <h3>Mathilde B - Présidente</h3>
              <p>Étudiante en école de commerce</p>
            </div>
          </div>
        </div>
      </section>

      {/* Barre de séparation supplémentaire */}
      <div className="section-divider-bottom"></div>

      {/* Section localisation */}
      <section className="localisation-section">
        <h2>Localisation</h2>
        <p>
          Nous sommes situés à Raraka, un atoll des Tuamotu en Polynésie française. Venez nous rendre visite pour en savoir plus sur nos projets en cours et notre impact local.
        </p>
        <div id="map" className="map"></div>
      </section>

      {/* Barre de séparation supplémentaire */}
      <div className="section-divider-bottom"></div>

      {/* Section de contact */}
      <section className="contact-section">
        <h2>Contactez-nous</h2>
        <div className="contact-container">
          <div className="contact-form-container">
            <form className="contact-form" onSubmit={handleSubmit}>
              <label htmlFor="name">Nom :</label>
              <input
                type="text"
                id="name"
                name="name"
                placeholder="Votre nom complet"
                value={contactForm.name}
                onChange={handleInputChange}
              />

              <label htmlFor="email">Email <span style={{ color: 'red' }}>*</span> :</label>
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Votre adresse email"
                value={contactForm.email}
                onChange={handleInputChange}
                required
              />

              <label htmlFor="message">Message <span style={{ color: 'red' }}>*</span> :</label>
              <textarea
                id="message"
                name="message"
                rows="5"
                placeholder="Votre message..."
                value={contactForm.message}
                onChange={handleInputChange}
                required
              ></textarea>

              <button type="submit" className="contact-button">Envoyer</button>
              {formMessage && <p className="form-message">{formMessage}</p>}
            </form>
          </div>
          <div className="contact-image">
            <img src={contactImage} alt="Contact illustration" />
          </div>
        </div>
      </section>
    </div>
  );
}

export default Coordonnees;
