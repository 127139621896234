// src/pages/SignupForm.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { signupUser } from '../services/authService';
import './styles/SignupForm.css';

const SignupForm = () => {
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const handleSignup = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setMessage('');

    try {
      await signupUser(
        email,
        password,
        (verificationMessage) => {
          setMessage(verificationMessage);  // Affiche le message une fois l'email de vérification envoyé
        },
        () => {
          setMessage('Votre adresse email a été vérifiée. Vous êtes maintenant connecté.');
          navigate('/dashboard');  // Redirection après vérification
        },
        (errorMessage) => {
          setMessage(errorMessage);  // Affiche l'erreur si l'inscription échoue
        }
      );
    } catch (error) {
      setMessage('Erreur lors de l’inscription. Veuillez réessayer.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="signup-form">
      <h2>Sign up</h2>
      {message && <div className="message">{message}</div>}
      <form onSubmit={handleSignup}>
        <div className="form-group">
          <label>Email address</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>

        <div className="form-group">
          <label>Username</label>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </div>

        <div className="form-group">
          <label>Password</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>

        <button type="submit" className="signup-button">
          {isLoading ? 'Loading...' : 'Sign up'}
        </button>

        <p className="terms-text">
          By signing up to create an account, you are accepting our <a href="/" className="link">terms of service</a> and <a href="/" className="link">privacy policy</a>
        </p>
      </form>
    </div>
  );
};

export default SignupForm;
