// src/components/LoginForm.js
import React, { useState } from 'react';
import './styles/LoginForm.css';
import { FaGoogle } from 'react-icons/fa';
import { loginUser, loginWithGoogle, resetPassword } from '../services/authService';
import { useNavigate } from 'react-router-dom';

const LoginForm = ({ onClose, openSignup }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setMessage('');
    setSuccessMessage('');

    try {
      await loginUser(email, password, () => {
        setSuccessMessage('Connexion réussie :)');
        setTimeout(() => {
          navigate('/dashboard');
          if (onClose) onClose();
        }, 2000);
      }, (error) => {
        setMessage("mots de passe ou email incorecte");
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleLoginWithGoogle = async () => {
    setIsLoading(true);
    setMessage('');
    setSuccessMessage('');

    try {
      await loginWithGoogle(() => {
        setSuccessMessage('Connexion réussie avec Google :)');
        setTimeout(() => {
          navigate('/dashboard');
          if (onClose) onClose();
        }, 2000);
      }, (error) => {
        setMessage(error);
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleResetPassword = async () => {
    if (!email) {
      setMessage('Veuillez entrer votre adresse email pour réinitialiser votre mot de passe.');
      return;
    }

    try {
      await resetPassword(email, (successMessage) => {
        setMessage(successMessage);
      }, (error) => {
        setMessage(error);
      });
    } catch (error) {
      setMessage('Erreur lors de la réinitialisation du mot de passe.');
    }
  };

  return (
    <div className="login-form">
      <h2>Connexion</h2>
      {message && <div className="message">{message}</div>}
      {successMessage && <div className="success-message">{successMessage}</div>}
      <p>
        Bienvenue ! Ou{' '}
        <span className="link" onClick={openSignup} style={{ cursor: 'pointer' }}>
          Créer un compte
        </span>
      </p>
      <form onSubmit={handleLogin}>
        <div className="form-group">
          <label>Adresse email</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>

        <div className="form-group">
          <label>Mot de passe</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>

        <button type="submit" className="login-button">
          {isLoading ? 'Chargement...' : 'Se connecter'}
        </button>
        <button type="button" className="google-login" onClick={handleLoginWithGoogle}>
          <FaGoogle className="google-icon" /> Se connecter avec Google
        </button>

        <p className="forgot-password-text">
          <span className="link" onClick={handleResetPassword}>Mot de passe oublié ?</span>
        </p>
      </form>
    </div>
  );
};

export default LoginForm;
