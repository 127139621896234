import React from 'react';
import { FaRobot, FaSatellite, FaCloud } from 'react-icons/fa'; // Icônes pour représenter les technologies
import './styles/InnovationModal.css'; // Importer le CSS spécifique

const InnovationModal = ({ onClose }) => {
  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <button className="modal-close" onClick={onClose}>
          &times;
        </button>
        <h2>Innovation et Technologie</h2>
        <p>
          Nous utilisons les dernières technologies pour protéger les écosystèmes et améliorer les pratiques de conservation. Découvrez comment nous intégrons l'innovation pour un impact durable.
        </p>

        <div className="tech-list">
          <div className="tech-item">
            <FaRobot className="tech-icon" />
            <div className="tech-info">
              <h3>Automatisation et Robotique</h3>
              <p>
                Des robots sous-marins sont utilisés pour explorer les récifs coralliens, surveiller leur santé et collecter des données précieuses sans perturber la biodiversité locale.
              </p>
            </div>
          </div>

          <div className="tech-item">
            <FaSatellite className="tech-icon" />
            <div className="tech-info">
              <h3>Imagerie par Satellite</h3>
              <p>
                Grâce à des images satellites, nous surveillons les changements environnementaux à grande échelle, ce qui permet de réagir rapidement aux menaces écologiques telles que la déforestation et la pollution.
              </p>
            </div>
          </div>

          <div className="tech-item">
            <FaCloud className="tech-icon" />
            <div className="tech-info">
              <h3>Analyse de Données dans le Cloud</h3>
              <p>
                Nous utilisons l'intelligence artificielle et l'apprentissage automatique pour analyser d'énormes quantités de données sur la biodiversité et fournir des solutions efficaces pour la conservation.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InnovationModal;
