import React from 'react';
import './styles/EducationModal.css';
import { FiBookOpen, FiUsers, FiAward } from 'react-icons/fi'; // Icônes Feather

const EducationModal = ({ onClose }) => {
  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="modal-close" onClick={onClose}>&times;</button>
        <div className="modal-header">
          <h2>Éducation et Sensibilisation</h2>
        </div>
        <div className="modal-body">
          <p>
            Nos programmes éducatifs visent à sensibiliser les communautés locales et les visiteurs à l'importance de la conservation de l'environnement. À travers des ateliers, des conférences, et des initiatives locales, nous mettons l'accent sur la transmission des savoirs pour un impact durable.
          </p>
          <div className="stats-section">
            <div className="stat-card">
              <FiBookOpen className="stat-icon" />
              <h3>Programmes Éducatifs</h3>
              <p>Ateliers interactifs pour apprendre l'importance de la biodiversité.</p>
            </div>
            <div className="stat-card">
              <FiUsers className="stat-icon" />
              <h3>Engagement Communautaire</h3>
              <p>Implication des habitants et visiteurs dans nos actions de sensibilisation.</p>
            </div>
            <div className="stat-card">
              <FiAward className="stat-icon" />
              <h3>Impact Mesurable</h3>
              <p>Nos programmes ont sensibilisé plus de 5 000 personnes depuis leur création.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EducationModal;
