import { db } from '../api/firebaseConfig';
import { doc, setDoc, getDoc, deleteDoc, updateDoc, collection, addDoc } from 'firebase/firestore';

/**
 * Ajoute des informations utilisateur dans la collection Firestore.
 *
 * @param {string} userId - L'ID de l'utilisateur Firebase.
 * @param {object} userInfo - L'objet contenant les informations de l'utilisateur.
 * @param {function} [onSuccess] - Callback pour appeler en cas de succès.
 * @param {function} [onError] - Callback pour appeler en cas d'erreur.
 */
export const addUserInfo = async (userId, userInfo, onSuccess, onError) => {
  try {
    const infoToAdd = { role: 'user', ...userInfo }; 
    await setDoc(doc(db, 'users', userId), infoToAdd);
    if (onSuccess) {
      onSuccess();
    }
  } catch (error) {
    if (onError) {
      onError(`Erreur : ${error.message}`);
    }
  }
};

/**
 * Récupère les informations utilisateur à partir de Firestore.
 *
 * @param {string} userId - L'ID de l'utilisateur Firebase.
 * @returns {Promise<object>} - DocumentSnapshot contenant les informations utilisateur.
 * @throws {Error} - Si une erreur survient pendant la récupération.
 */
export const getUserInfo = async (userId) => {
  try {
    const userDoc = await getDoc(doc(db, 'users', userId));
    return userDoc;
  } catch (error) {
    console.error('Erreur lors de la récupération des informations utilisateur:', error);
    throw new Error(`Erreur lors de la récupération des informations utilisateur : ${error.message}`);
  }
};

/**
 * Met à jour les informations utilisateur dans Firestore.
 *
 * @param {string} userId - L'ID de l'utilisateur Firebase.
 * @param {object} updatedInfo - L'objet contenant les informations mises à jour.
 * @param {function} [onSuccess] - Callback pour appeler en cas de succès.
 * @param {function} [onError] - Callback pour appeler en cas d'erreur.
 */
export const updateUserInfo = async (userId, updatedInfo, onSuccess, onError) => {
  try {
    await updateDoc(doc(db, 'users', userId), updatedInfo);
    if (onSuccess) {
      onSuccess();
    }
  } catch (error) {
    if (onError) {
      onError(`Erreur : ${error.message}`);
    }
  }
};

/**
 * Supprime un compte utilisateur de la collection Firestore.
 *
 * @param {string} userId - L'ID de l'utilisateur Firebase.
 * @param {function} [onSuccess] - Callback pour appeler en cas de succès.
 * @param {function} [onError] - Callback pour appeler en cas d'erreur.
 */
export const deleteUserAccount = async (userId, onSuccess, onError) => {
  try {
    await deleteDoc(doc(db, 'users', userId));
    if (onSuccess) {
      onSuccess();
    }
  } catch (error) {
    if (onError) {
      onError(`Erreur : ${error.message}`);
    }
  }
};

/**
 * Ajoute un message de contact dans la collection Firestore "contact-us".
 *
 * @param {object} contactInfo - L'objet contenant les informations de contact (email, message).
 * @param {function} [onSuccess] - Callback pour appeler en cas de succès.
 * @param {function} [onError] - Callback pour appeler en cas d'erreur.
 */
export const addContactMessage = async (contactInfo, onSuccess, onError) => {
    try {
      await addDoc(collection(db, 'contact-us'), contactInfo);
      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      if (onError) {
        onError(`Erreur : ${error.message}`);
      }
    }
  };

// Fonction pour récupérer le rôle de l'utilisateur à partir de Firestore
export const getRoleFromFirestore = async (userId) => {
  try {
    const userDoc = await getDoc(doc(db, 'users', userId));
    if (userDoc.exists()) {
      const userData = userDoc.data();
      return userData.role || null; // Récupérer le rôle de l'utilisateur
    } else {
      return null;
    }
  } catch (error) {
    console.error('Erreur lors de la récupération du rôle de l\'utilisateur:', error);
    throw new Error(`Erreur lors de la récupération du rôle de l'utilisateur : ${error.message}`);
  }
};