import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  signInWithEmailAndPassword,
  signOut,
  signInWithPopup,
  sendPasswordResetEmail,
} from 'firebase/auth';
import Cookies from 'js-cookie';
import { auth, googleProvider } from '../api/firebaseConfig';
import { addUserInfo } from './firestoreService';

export const signupUser = async (email, password, onEmailVerificationSent, onEmailVerified, onError) => {
  try {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;

    await sendEmailVerification(user);
    if (onEmailVerificationSent) {
      onEmailVerificationSent('Un email de vérification a été envoyé à votre adresse email. Veuillez vérifier.');
    }

    await addUserInfo(user.uid, { isFirstLogin: true });

    const interval = setInterval(async () => {
      await user.reload();
      if (user.emailVerified) {
        clearInterval(interval);
        Cookies.set('userId', user.uid, { expires: 7, secure: true, sameSite: 'Strict' }); // Sécurisation du cookie
        if (onEmailVerified) {
          onEmailVerified();
        }
      }
    }, 5000);
  } catch (error) {
    if (onError) {
      onError(`Erreur : ${error.message}`);
    }
  }
};

// Fonction pour connecter un utilisateur avec email et mot de passe
export const loginUser = async (email, password, onSuccess, onError) => {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;

    if (!user.emailVerified) {
      throw new Error('Veuillez vérifier votre email avant de vous connecter.');
    }

    Cookies.set('userId', user.uid, { expires: 7, secure: true, sameSite: 'Strict' });

    if (onSuccess) {
      onSuccess();
    }
  } catch (error) {
    if (onError) {
      onError(`Erreur : ${error.message}`);
    }
  }
};

// Fonction pour connecter un utilisateur via Google
export const loginWithGoogle = async (onSuccess, onError) => {
  try {
    const userCredential = await signInWithPopup(auth, googleProvider);
    const user = userCredential.user;
    Cookies.set('userId', user.uid, { expires: 7, secure: true, sameSite: 'Strict' });
    if (onSuccess) {
      onSuccess();
    }
  } catch (error) {
    if (onError) {
      onError(`Erreur : ${error.message}`);
    }
  }
};

export const logoutUser = async (onSuccess, onError) => {
  try {
    await signOut(auth);
    localStorage.removeItem('isFirstLogin');
    if (onSuccess) {
      onSuccess();
    }
  } catch (error) {
    if (onError) {
      onError(`Erreur : ${error.message}`);
    }
  }
};

export const resetPassword = async (email, onSuccess, onError) => {
  try {
    await sendPasswordResetEmail(auth, email);
    if (onSuccess) {
      onSuccess('Un email de réinitialisation a été envoyé à votre adresse email.');
    }
  } catch (error) {
    if (onError) {
      onError(`Erreur : ${error.message}`);
    }
  }
};
