import React, { useState } from 'react';
import './styles/Service.css';
import { FiFeather, FiBookOpen, FiBriefcase, FiCpu, FiGlobe, FiLifeBuoy } from 'react-icons/fi';
import { FaLeaf } from 'react-icons/fa';
import membershipImage from '../assets/images/raraka.jpg';
import biodiversityImage from '../assets/images/biodiversity.jpg';
import EnvironmentModal from '../components/EnvironmentModal';
import EducationModal from '../components/EducationModal';
import LocalProjectsModal from '../components/LocalProjectsModal';
import InnovationModal from '../components/InnovationModal';
import SignupForm from '../components/SignupForm';

function Services() {
  const [isModalOpen, setIsModalOpen] = useState({
    environment: false,
    education: false,
    localProjects: false,
    innovation: false,
    signup: false,
  });

  const openModal = (modalType) => {
    setIsModalOpen((prevState) => ({
      ...prevState,
      [modalType]: true,
    }));
  };

  const closeModal = (modalType) => {
    setIsModalOpen((prevState) => ({
      ...prevState,
      [modalType]: false,
    }));
  };

  const handleBiodiversityButtonClick = () => {
    window.open('https://www.senat.fr/rap/r17-533/r17-5332.html#:~:text=1)%20La%20biodiversit%C3%A9%20polyn%C3%A9sienne%20menac%C3%A9e,que%20six%20sont%20d%C3%A9j%C3%A0%20%C3%A9teintes.', '_blank');
  };

  return (
    <div className="services-container">
      {/* Modals */}
      {isModalOpen.environment && <EnvironmentModal onClose={() => closeModal('environment')} />}
      {isModalOpen.education && <EducationModal onClose={() => closeModal('education')} />}
      {isModalOpen.localProjects && <LocalProjectsModal onClose={() => closeModal('localProjects')} />}
      {isModalOpen.innovation && <InnovationModal onClose={() => closeModal('innovation')} />}
      {/* Modal SignupForm */}
      {isModalOpen.signup && (
        <div className="modal-overlay" onClick={() => closeModal('signup')}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <button className="modal-close" onClick={() => closeModal('signup')}>
              &times;
            </button>
            <SignupForm />
          </div>
        </div>
      )}

      <header className="services-header">
        <h1>Nos Services</h1>
        <p>
          Découvrez nos services dédiés à la préservation des Tuamotu, à la sensibilisation des communautés locales et au développement durable.
        </p>
      </header>

      <section className="services-content">
        {/* Modal pour Préservation de l'Environnement */}
        <div className="service-item fade-in">
          <div className="service-icon">
            <FiFeather size={50} color="#007BFF" />
          </div>
          <div className="service-info">
            <h2>Préservation de l'Environnement</h2>
            <p>
              Protégeons ensemble la biodiversité unique des îles Tuamotu à travers des actions locales concrètes.
            </p>
            <button className="service-button" onClick={() => openModal('environment')}>En savoir plus</button>
          </div>
        </div>

        {/* Modal pour Éducation et Sensibilisation */}
        <div className="service-item fade-in">
          <div className="service-icon">
            <FiBookOpen size={50} color="#007BFF" />
          </div>
          <div className="service-info">
            <h2>Éducation et Sensibilisation</h2>
            <p>
              Nos programmes éducatifs sensibilisent les communautés et les visiteurs à l'importance de la conservation.
            </p>
            <button className="service-button" onClick={() => openModal('education')}>Lire la suite</button>
          </div>
        </div>

        {/* Modal pour Soutien aux Projets Locaux */}
        <div className="service-item fade-in">
          <div className="service-icon">
            <FiBriefcase size={50} color="#007BFF" />
          </div>
          <div className="service-info">
            <h2>Soutien aux Projets Locaux</h2>
            <p>
              Nous soutenons les initiatives locales en matière de développement durable pour un avenir meilleur.
            </p>
            <button className="service-button" onClick={() => openModal('localProjects')}>Voir les projets</button>
          </div>
        </div>

        {/* Modal pour Innovation et Technologie */}
        <div className="service-item fade-in">
          <div className="service-icon">
            <FiCpu size={50} color="#007BFF" />
          </div>
          <div className="service-info">
            <h2>Innovation et Technologie</h2>
            <p>
              Nous utilisons les dernières technologies pour protéger les écosystèmes et améliorer les pratiques de conservation.
            </p>
            <button className="service-button" onClick={() => openModal('innovation')}>Explorer l'innovation</button>
          </div>
        </div>
      </section>

      {/* Barre de séparation */}
      <div className="section-divider"></div>

      {/* Nouvelle rubrique : Devenir Membre */}
      <section className="membership-section">
        <div className="membership-container">
          <div className="membership-text">
            <h2>Devenir Membre</h2>
            <p>
              En tant que membre de notre association, vous contribuez directement à nos actions pour la préservation des Tuamotu. Rejoignez-nous pour faire partie d’un réseau engagé et participer activement à la protection de notre environnement.
            </p>
            {/* Button to open SignupForm modal */}
            <button className="membership-button" onClick={() => openModal('signup')}>
              Rejoindre l'association
            </button>
          </div>
          <div className="membership-image">
            <img src={membershipImage} alt="Illustration adhésion" />
          </div>
        </div>
      </section>


      {/* Barre de séparation */}
      <div className="section-divider"></div>

      {/* Nouvelle rubrique : Rapport sur la Biodiversité */}
      <section className="biodiversity-section">
        <div className="biodiversity-container">
          <div className="biodiversity-image">
            <img src={biodiversityImage} alt="Illustration Biodiversité" />
          </div>
          <div className="biodiversity-text">
            <h2>Rapport sur la Biodiversité</h2>
            <p>
              La biodiversité des Tuamotu est une richesse inestimable. Nous vous présentons un rapport détaillé sur l’état de la biodiversité locale, les menaces qui pèsent sur elle et nos efforts pour la protéger.
            </p>
            <div className="biodiversity-icons">
              <div className="icon-item">
                <FiGlobe size={50} color="#28a745" />
                <p>Conservation Globale</p>
              </div>
              <div className="icon-item">
                <FiLifeBuoy size={50} color="#17a2b8" />
                <p>Sauvetage des Espèces</p>
              </div>
              <div className="icon-item">
                <FaLeaf size={50} color="#007BFF" />
                <p>Protection de la nature</p>
              </div>
            </div>
            <button className="biodiversity-button" onClick={handleBiodiversityButtonClick}>Explorer le rapport</button>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Services;
