import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Modal from './Modal'; // Import the Modal component
import LoginForm from './LoginForm'; // Import LoginForm from the same folder
import SignupForm from './SignupForm'; // Import SignupForm from the same folder
import { auth } from '../api/firebaseConfig'; // Import Firebase auth
import { logoutUser } from '../services/authService'; // Import logout function
import './styles/Navbar.css'; // Import CSS for uniform styling

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      setUser(currentUser);
    });
    return () => unsubscribe();
  }, []);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const openModal = (content) => {
    setModalContent(content);
    setIsOpen(false);
  };

  const closeModal = () => {
    setModalContent(null);
  };

  const handleLogout = async () => {
    try {
      await logoutUser(() => {
        navigate('/'); // Redirect to home after logout
      });
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  return (
    <>
      <nav className="navbar">
        <div className="navbar-logo">
          <h1>Teoraoraka</h1>
          <span>From Tuamotu to France</span>
        </div>
        <button className="menu-toggle" onClick={toggleMenu}>
          ☰
        </button>
        <ul className={`nav-links ${isOpen ? 'active' : ''}`}>
          <li>
            <Link to="/" onClick={() => setIsOpen(false)}>Accueil</Link>
          </li>
          <li>
            <Link to="/service" onClick={() => setIsOpen(false)}>Service</Link>
          </li>
          <li>
            <Link to="/Coordonnees" onClick={() => setIsOpen(false)}>Coordonnées</Link>
          </li>
          <li>
            <Link to="/ProjetAssociatif" onClick={() => setIsOpen(false)}>Projet associatif</Link>
          </li>
          <li>
            <Link to="/EnSavoirPlus" onClick={() => setIsOpen(false)}>En savoir plus</Link>
          </li>
        </ul>
        <div className="navbar-buttons">
          {user ? (
            <>
              <Link to="/dashboard" className="dashboard-button">Dashboard</Link>
              <button className="logout-button" onClick={handleLogout}>Déconnexion</button>
            </>
          ) : (
            <>
              <button className="connexion-button" onClick={() => openModal('login')}>Connexion</button>
              <button className="join-button" onClick={() => openModal('signup')}>Devenir membres</button>
            </>
          )}
        </div>
      </nav>

      <Modal show={!!modalContent} onClose={closeModal}>
        {modalContent === 'login' && <LoginForm onClose={closeModal} openSignup={() => setModalContent('signup')} />}
        {modalContent === 'signup' && <SignupForm />}
      </Modal>
    </>
  );
}

export default Navbar;
