import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './styles/Home.css';
import ModalAvecDetails from '../components/ModalAvecDetails';
import image1 from '../assets/images/image-1.jpg';
import image2 from '../assets/images/image-2.jpg';
import image3 from '../assets/images/raraka.jpg';
import icon1 from '../assets/icone/icon-1.png';
import icon2 from '../assets/icone/icon-2.png';
import icon3 from '../assets/icone/icon-3.png';
import icon4 from '../assets/icone/icon-4.png';

function Home() {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const elements = document.querySelectorAll('.fade-in');
      elements.forEach((el) => {
        const position = el.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;
        if (position < windowHeight - 100) {
          el.classList.add('visible');
        }
      });
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleLearnMoreClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div className="home-container">
      {/* Overlay pour l'image de fond */}
      <div className="home-overlay"></div>

      {/* Première section */}
      <section className="home-fullscreen fade-in">
        <div className="home-title">
          <h2>Ensemble, construisons un avenir meilleur pour nos îles !</h2>
        </div>
        <div className="home-content-wrapper">
          <img src={image1} alt="Plage" className="home-image" />
          <div className="home-content">
            <h3>Quels sont les valeurs que vous souhaitez mettre en avant ?</h3>
            <p>
              En tant qu'association à but non lucratif engagée dans la préservation de l'environnement et le développement durable des Tuamotu, nous avons à cœur de communiquer clairement notre mission et nos actions. Notre site web a été conçu pour être simple, efficace et accessible à tous, nous souhaitons vous offrir une expérience utilisateur agréable tout en vous informant sur les enjeux auxquels sont confrontés les Tuamotu et sur les actions que nous menons pour y remédier.
            </p>
            <button className="learn-more-button" onClick={() => navigate('/EnSavoirPlus')}>
              En savoir plus
            </button>
          </div>
        </div>
      </section>

      {/* Barre de séparation */}
      <div className="section-divider"></div>

      {/* Deuxième section */}
      <section className="about-section fade-in">
        <div className="about-content-wrapper">
          <img src={image2} alt="Atoll" className="about-image" />
          <div className="about-content">
            <h2>Qui sommes nous ?</h2>
            <p>
              Teoraoraka est une association à but non lucratif qui œuvre pour la protection de l'environnement et la préservation de la biodiversité. Nous croyons en un avenir où l'homme et la nature vivent en harmonie. Nos actions s'articulent autour de plusieurs axes : la sensibilisation du public, la protection des espaces naturels, le soutien à la recherche scientifique et la promotion de pratiques éco-responsables. Rejoignez-nous pour construire un monde plus vert !
            </p>
            <button className="learn-more-button" onClick={handleLearnMoreClick}>
              Lire la suite
            </button>
          </div>
        </div>
      </section>

      {/* Barre de séparation supplémentaire */}
      <div className="section-divider-bottom"></div>

      {/* Section "Notre But" */}
      <section className="purpose-section fade-in">
        <h2>Notre But</h2>
        <p>Biodiversité, environnement, Soutien local, Développement durable</p>
        <div className="purpose-container">
          {[{ icon: icon1, title: 'Préservation de la biodiversité', text: "L'atoll de Raraka abrite une biodiversité marine et terrestre exceptionnelle, menacée par le changement climatique, la pollution et la surpêche. Notre association œuvre à protéger cet écosystème fragile en créant des aires marines protégées, en soutenant la pêche durable et en sensibilisant les populations locales à l'importance de la biodiversité. Rejoignez-nous pour les générations futures." },
            { icon: icon2, title: 'Sensibilisation environnementale', text: "Nous menons des actions de sensibilisation auprès de tous les publics, des plus jeunes aux adultes, pour les informer sur les enjeux environnementaux qui touchent notre planète et les inciter à adopter des comportements plus responsables. À travers des programmes éducatifs ludiques et des ateliers pratiques, nous leur donnons les clés pour agir au quotidien en faveur de la biodiversité." },
            { icon: icon3, title: 'Soutien aux projets locaux', text: "Nous finançons une multitude de projets locaux qui favorisent l'adoption de pratiques durables au sein de la communauté. Qu'il s'agisse d'agriculture biologique, d'énergie renouvelable, de gestion des déchets ou d'économie circulaire, nous soutenons toutes les initiatives visant à construire un avenir plus durable." },
            { icon: icon4, title: 'Développement durable', text: "Nous encourageons la transition vers une économie circulaire, le développement des énergies renouvelables, l'amélioration de la gestion des ressources naturelles et la promotion de modes de vie plus durables. Nos actions visent à créer un avenir où les générations futures pourront vivre dans un environnement sain et prospère." }
          ].map(({icon, title, text}, index) => (
            <div key={index} className="purpose-card">
              <img src={icon} alt={title} />
              <h3>{title}</h3>
              <p>{text}</p>
              <button className="card-info-button" onClick={handleLearnMoreClick}>
                INFO
              </button>
            </div>
          ))}
        </div>
      </section>

      {/* Barre de séparation supplémentaire */}
      <div className="section-divider-bottom"></div>

      {/* Section "À propos" */}
      <section className="about-us-section fade-in">
        <div className="about-us-container">
          <div className="about-us-text">
            <h2>À propos</h2>
            <p>Te Ora O Raraka, c'est bien plus qu'une association. C'est un engagement profond pour la préservation de l'âme de Raraka. Guidés par les valeurs de respect, de partage et de solidarité, nous œuvrons chaque jour pour transmettre aux générations futures l'héritage exceptionnel de cet atoll. En protégeant la biodiversité unique de Raraka, en valorisant son patrimoine culturel et en soutenant les initiatives locales, nous contribuons à bâtir un avenir où l'homme et la nature vivent en harmonie. Découvrez Te Ora O Raraka, une association qui fédère les énergies pour préserver le cœur battant de Raraka. En étroite collaboration avec les habitants, les autorités locales, les scientifiques et les associations partenaires, nous menons des actions concrètes pour protéger l'environnement et valoriser le patrimoine culturel de l'atoll. Ensemble, nous écrivons une nouvelle page de l'histoire de Raraka, une histoire où la tradition rencontre l'innovation.</p>
          </div>
          <img src={image3} alt="Raraka" className="about-us-image" />
        </div>
      </section>

      {/* Modal avec plus de détails */}
      <ModalAvecDetails show={showModal} onClose={handleCloseModal} />
    </div>
  );
}

export default Home;
