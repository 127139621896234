import React from 'react';
import { FaLeaf, FaHandsHelping, FaGlobe, FaRecycle } from 'react-icons/fa';
import './styles/ModalAvecDetails.css';

const ModalAvecDetails = ({ show, onClose }) => {
  if (!show) {
    return null;
  }

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <button className="modal-close" onClick={onClose}>
          &times;
        </button>
        <div className="modal-header">
          <h2>Qui sommes-nous ?</h2>
        </div>
        <div className="modal-body">
          <p>
            Teoraoraka est une association à but non lucratif qui œuvre pour la protection de l'environnement et la préservation de la biodiversité. Nous croyons en un avenir où l'homme et la nature vivent en harmonie.
          </p>
          <div className="stats-section">
            <div className="stat-card">
              <FaLeaf className="stat-icon" />
              <h3>+5000</h3>
              <p>Espèces protégées</p>
            </div>
            <div className="stat-card">
              <FaHandsHelping className="stat-icon" />
              <h3>+300</h3>
              <p>Bénévoles actifs</p>
            </div>
            <div className="stat-card">
              <FaGlobe className="stat-icon" />
              <h3>10</h3>
              <p>Projets internationaux</p>
            </div>
            <div className="stat-card">
              <FaRecycle className="stat-icon" />
              <h3>+100</h3>
              <p>Ateliers de sensibilisation</p>
            </div>
          </div>
          <p>
            Nos actions s'articulent autour de plusieurs axes : la sensibilisation du public, la protection des espaces naturels, le soutien à la recherche scientifique et la promotion de pratiques éco-responsables. Rejoignez-nous pour construire un monde plus vert !
          </p>
        </div>
      </div>
    </div>
  );
};

export default ModalAvecDetails;