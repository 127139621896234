// ProjetAssociatif.js
import React from 'react';
import './styles/ProjetAssociatif.css';
import { FaHandsHelping, FaLeaf, FaRecycle, FaHeart } from 'react-icons/fa';
import projectImage1 from '../assets/images/project-1.jpg';
import projectImage2 from '../assets/images/project-2.jpg';
import beforeAfterImage from '../assets/images/create.jpg';

function ProjetAssociatif() {
  return (
    <div className="projet-container">
      <header className="projet-header">
        <h1>Nos projets pour préserver Raraka</h1>
        <p>
          Découvrez comment nous agissons au quotidien pour protéger l'environnement et le patrimoine culturel de l'atoll. 
          Ensemble, nous créons un avenir durable pour Raraka, en préservant nos ressources naturelles et en transmettant notre savoir-faire aux générations futures.
        </p>
      </header>

      {/* Barre de séparation supplémentaire */}
      <div className="section-divider-bottom"></div>

      {/* Introduction */}
      <section className="projet-intro">
        <h2>Protégeons ensemble notre patrimoine naturel et culturel</h2>
        <p>
          Notre association met tout en œuvre pour préserver la biodiversité, protéger les écosystèmes fragiles de Raraka, 
          et promouvoir un développement durable qui respecte l'environnement et la culture locale. 
          Nous travaillons main dans la main avec la communauté pour mettre en place des initiatives concrètes et efficaces qui ont un impact positif sur notre environnement et notre société.
        </p>
      </section>

      {/* Barre de séparation supplémentaire */}
      <div className="section-divider-bottom"></div>

      {/* Nos projets en cours */}
      <section className="projets-cours-section">
        <h2>En action pour Raraka</h2>
        <p>
          Découvrez nos projets actuels et rejoignez-nous ! Que ce soit par la restauration d'espaces naturels ou par la sensibilisation des habitants,
          chaque projet vise à protéger les trésors de notre atoll tout en favorisant le bien-être des communautés locales.
        </p>
        <div className="projets-container">
          <div className="projet-item">
            <img src={projectImage1} alt="Restauration de la forêt de cocotiers" />
            <h3>Restauration de la forêt de cocotiers</h3>
            <p>
              La forêt de cocotiers de Raraka est essentielle à l'équilibre de l'écosystème. Nous replantons des arbres, mettons en place des mesures de protection, 
              et organisons des ateliers de sensibilisation pour la population locale afin d'assurer une gestion durable des ressources naturelles.
            </p>
          </div>
          <div className="projet-item">
            <img src={projectImage2} alt="Protection des récifs coralliens" />
            <h3>Protection des récifs coralliens</h3>
            <p>
              Nos efforts se concentrent sur la sauvegarde des récifs coralliens de Raraka, essentiels à la biodiversité marine et à la pêche locale.
              En collaborant avec des experts en biologie marine, nous mettons en œuvre des actions pour restaurer et préserver les récifs, 
              garantissant ainsi la survie des espèces qui y habitent et les moyens de subsistance de la communauté.
            </p>
          </div>
        </div>
      </section>

      {/* Barre de séparation supplémentaire */}
      <div className="section-divider-bottom"></div>

      {/* Nos réalisations */}
      <section className="realisation-section">
        <h2>Ensemble, nous avons déjà accompli beaucoup !</h2>
        <p>
          Découvrez nos réalisations passées et leur impact sur Raraka. Grâce à la mobilisation des habitants, des bénévoles et de nos partenaires,
          nous avons pu mener des projets ambitieux et concrets qui ont transformé notre atoll. Nous sommes fiers de partager avec vous ces réussites qui ont permis de redonner vie à notre environnement.
        </p>
        <div className="realisation-gallery">
          <img src={beforeAfterImage} alt="Avant et après" />
        </div>
        <blockquote className="temoignage">
          <p>"Grâce aux efforts de l'association, la forêt de cocotiers a retrouvé sa splendeur d'antan. Merci à tous les bénévoles !" - Habitante de Raraka</p>
        </blockquote>
      </section>

      {/* Barre de séparation supplémentaire */}
      <div className="section-divider-bottom"></div>

      {/* Participez */}
      <section className="participation-section">
        <h2>Rejoignez-nous !</h2>
        <p>
          Devenez acteur du changement et soutenez nos projets. Que vous souhaitiez donner de votre temps, faire un don, parrainer un projet, ou simplement adhérer à notre association,
          il y a toujours une place pour vous. Votre engagement est précieux et fera une réelle différence pour notre communauté et notre environnement.
        </p>
        <div className="participation-options">
          <div className="participation-item">
            <FaHandsHelping size={50} color="#007BFF" />
            <h3>Devenir bénévole</h3>
            <p>
              Participez directement sur le terrain et aidez-nous à protéger l'atoll de Raraka. Que ce soit en replantant des cocotiers, en nettoyant les plages ou en sensibilisant les habitants,
              chaque coup de main est essentiel pour faire la différence.
            </p>
          </div>
          <div className="participation-item">
            <FaHeart size={50} color="#FF4500" />
            <h3>Faire un don</h3>
            <p>
              Contribuez financièrement à nos projets et aidez-nous à avoir un impact durable. Chaque don est investi dans la préservation de notre environnement
              et dans des actions qui bénéficient directement à la communauté de Raraka.
            </p>
          </div>
          <div className="participation-item">
            <FaRecycle size={50} color="#28a745" />
            <h3>Parrainer un projet</h3>
            <p>
              Soutenez un projet spécifique de votre choix et faites partie du changement. Vous pouvez parrainer la restauration d'une partie de la forêt de cocotiers,
              ou contribuer à la protection des récifs coralliens. Ensemble, nous pouvons aller encore plus loin.
            </p>
          </div>
          <div className="participation-item">
            <FaLeaf size={50} color="#007BFF" />
            <h3>Adhérer à l'association</h3>
            <p>
              Rejoignez notre association et bénéficiez de nombreux avantages, tels que l'accès à des ateliers exclusifs, des événements de sensibilisation,
              et la satisfaction de participer activement à la préservation de Raraka. Ensemble, nous construisons un avenir durable.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ProjetAssociatif;
