import React from 'react';
import './styles/EnvironmentModal.css';
import { FiGlobe, FiLifeBuoy } from 'react-icons/fi'; // Import des icônes Feather
import { FaLeaf } from 'react-icons/fa'; // Import de l'icône de feuille

const EnvironmentModal = ({ onClose }) => {
  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="modal-close" onClick={onClose}>&times;</button>
        <div className="modal-header">
          <h2>Préservation de l'Environnement</h2>
        </div>
        <div className="modal-body">
          <p>
            Notre association œuvre activement pour la préservation de la biodiversité des îles Tuamotu. En collaborant avec les communautés locales, nous mettons en place des initiatives visant à protéger les espèces menacées et leurs habitats naturels. Voici quelques-unes des actions que nous menons :
          </p>
          <div className="stats-section">
            <div className="stat-card">
              <FiGlobe className="stat-icon" />
              <h3>Conservation Globale</h3>
              <p>Initiatives locales et internationales pour la conservation des écosystèmes.</p>
            </div>
            <div className="stat-card">
              <FiLifeBuoy className="stat-icon" />
              <h3>Sauvetage des Espèces</h3>
              <p>Projets pour protéger les espèces en voie de disparition dans les Tuamotu.</p>
            </div>
            <div className="stat-card">
              <FaLeaf className="stat-icon" />
              <h3>Protection de la Nature</h3>
              <p>Encouragement des pratiques durables et de la régénération naturelle.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnvironmentModal;
