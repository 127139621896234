import React, { useEffect, useState } from 'react';
import { auth } from '../api/firebaseConfig';
import { getUserInfo, addUserInfo } from '../services/firestoreService';
import UserInfoForm from '../components/UserInfoForm';
import './styles/Dashboard.css';

function Dashboard() {
  const [showUserInfoForm, setShowUserInfoForm] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetchUserInfo = async () => {
      const currentUser = auth.currentUser;
      setUser(currentUser);

      if (currentUser) {
        try {
          const info = await getUserInfo(currentUser.uid);
          
          // Check if user info exists and if any critical fields are missing
          if (!info.exists() || !info.data().fullName || !info.data().phoneNumber || !info.data().address) {
            setShowUserInfoForm(true);
          } else {
            setUserInfo(info.data());
            setShowUserInfoForm(false);
          }
        } catch (error) {
          console.error('Erreur lors de la vérification des informations utilisateur:', error);
        }
      }
    };

    fetchUserInfo();
  }, []);

  // Handle form submission and store user info
  const handleUserInfoSubmit = async (submittedInfo) => {
    const currentUser = auth.currentUser;
    if (currentUser) {
      try {
        await addUserInfo(currentUser.uid, submittedInfo);
        setUserInfo(submittedInfo);
        setShowUserInfoForm(false);
      } catch (error) {
        console.error('Erreur lors de la soumission des informations utilisateur:', error);
      }
    }
  };

  return (
    <div className="dashboard-container">
      <h1>Bienvenue sur votre Dashboard</h1>

      {user && (
        <div className="user-greeting">
          <h2>Bonjour, {user.displayName || user.email}</h2>
        </div>
      )}

      {showUserInfoForm ? (
        <UserInfoForm onSubmit={handleUserInfoSubmit} />
      ) : (
        <div className="user-info">
          <h2>Informations utilisateur</h2>
          {userInfo ? (
            <div className="user-info-details">
              <p><strong>Nom complet:</strong> {userInfo.fullName || 'Non renseigné'}</p>
              <p><strong>Numéro de téléphone:</strong> {userInfo.phoneNumber || 'Non renseigné'}</p>
              {userInfo.address ? (
                <p>
                  <strong>Adresse:</strong> {userInfo.address.street || 'Non renseigné'}, 
                  {userInfo.address.city || 'Non renseigné'}, 
                  {userInfo.address.zipCode || 'Non renseigné'}, 
                  {userInfo.address.country || 'Non renseigné'}
                </p>
              ) : (
                <p>Adresse: Non renseignée</p>
              )}
              <p><strong>Date de naissance:</strong> {userInfo.birthDate || 'Non renseigné'}</p>
            </div>
          ) : (
            <p>Aucune information disponible.</p>
          )}
        </div>
      )}
    </div>
  );
}

export default Dashboard;

