import React, { createContext, useContext, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './api/firebaseConfig';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import Service from './pages/Service';
import Coordonnees from './pages/Coordonnees';
import ProjetAssociatif from './pages/ProjetAssociatif';
import EnSavoirPlus from './pages/EnSavoirPlus';
import Dashboard from './pages/Dashboard';
import NotFound from './pages/NotFound';  // Import de la page 404

// Création du contexte d'authentification
const AuthContext = createContext();

// Fournisseur d'authentification
const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      setLoading(false);
    });
    return () => unsubscribe();
  }, []);

  if (loading) {
    return <div>Chargement...</div>;
  }

  return <AuthContext.Provider value={{ user }}>{children}</AuthContext.Provider>;
};

// Hook pour utiliser le contexte d'authentification
const useAuth = () => useContext(AuthContext);

// Composant de route privée
const PrivateRoute = ({ children }) => {
  const { user } = useAuth();
  return user ? children : <Navigate to="/" />;
};

function App() {
  return (
    <AuthProvider>
      <Router>
        <div className="App">
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/service" element={<Service />} />
            <Route path="/coordonnees" element={<Coordonnees />} />
            <Route path="/ProjetAssociatif" element={<ProjetAssociatif />} />
            <Route path="/EnSavoirPlus" element={<EnSavoirPlus />} />
            <Route
              path="/dashboard"
              element={
                <PrivateRoute>
                  <Dashboard />
                </PrivateRoute>
              }
            />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;
