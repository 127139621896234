import React from 'react';
import { FaTree, FaSolarPanel, FaRecycle } from 'react-icons/fa'; // Importer des icônes
import './styles/LocalProjectsModal.css'; // Importer le CSS spécifique

const LocalProjectsModal = ({ onClose }) => {
  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <button className="modal-close" onClick={onClose}>
          &times;
        </button>
        <h2>Soutien aux Projets Locaux</h2>
        <p>
          Nous soutenons les initiatives locales en matière de développement durable pour un avenir meilleur.
        </p>

        <div className="projects-list">
          <div className="project-item">
            <FaTree className="project-icon" />
            <div className="project-info">
              <h3>Reforestation Communautaire</h3>
              <p>
                Ce projet vise à restaurer les forêts locales en impliquant la communauté, en plantant des espèces endémiques et en créant des zones de conservation.
              </p>
            </div>
          </div>

          <div className="project-item">
            <FaSolarPanel className="project-icon" />
            <div className="project-info">
              <h3>Énergies Renouvelables</h3>
              <p>
                Nous soutenons l’installation de panneaux solaires dans les communautés locales afin de réduire leur dépendance aux énergies fossiles et promouvoir une énergie durable.
              </p>
            </div>
          </div>

          <div className="project-item">
            <FaRecycle className="project-icon" />
            <div className="project-info">
              <h3>Gestion des Déchets</h3>
              <p>
                Un projet de recyclage qui vise à transformer les déchets en ressources, avec des ateliers de sensibilisation pour les habitants.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LocalProjectsModal;
