// src/pages/NotFound.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './styles/NotFound.css';

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <div className="not-found-container">
      <h1>Page non trouvée</h1>
      <p>La page que vous cherchez n'existe pas.</p>
      <button onClick={() => navigate('/')} className="go-home-button">Retourner à l'accueil</button>
    </div>
  );
};

export default NotFound;
